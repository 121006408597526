<template>
  <filter-item-wrapper
    title="Адреса"
    icon="menu_icon1"
    collapse="collapse-address"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterAddress"
        class="clear-section-filter"
        aria-label="Очистити фільтр"
        @click.prevent="resetFilterAddress"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div>
        <!-- Місто / Район -->
        <div class="row">
          <!-- Місто -->
          <div class="col-6">
            <div class="form-group form-group-filter">
              <label class="filter-modal-label">Місто</label>

              <multiselect
                v-model="city"
                placeholder="Почніть вводити:"
                :close-on-select="false"
                :clear-on-select="true"
                :show-no-results="false"
                label="name"
                track-by="id"
                :show-labels="false"
                :options="[
                  ...optionsCities,
                  {
                    id: 3,
                    is_city: false,
                    name: 'АР Kрим (скоро)',
                    $isDisabled: true
                  }
                ]"
                class="_sm"
              >
                <span slot="noResult">Немає результатів!!!</span>
              </multiselect>
            </div>
          </div>

          <!-- Район -->
          <div class="col-6">
            <div class="form-group form-group-filter">
              <label class="filter-modal-label">
                {{ isEnabledSuburb ? 'Район' : 'Адмін-район' }}
              </label>

              <multiselect
                v-model="district"
                placeholder="Почніть вводити:"
                :close-on-select="false"
                :clear-on-select="true"
                :show-no-results="false"
                label="name"
                track-by="id"
                :show-labels="false"
                :options="optionsDistricts"
                :multiple="true"
                class="_sm"
              >
                <span slot="noResult">Немає результатів!!!</span>
              </multiselect>
            </div>
          </div>
        </div>

        <!-- Suggested Tags -->
        <div v-if="!isEnabledSuburb" class="filter-group-btns pt-1">
          <div class="filter-group-btns__list">
            <li v-for="group in groups" :key="group.id" class="col-6">
              <button
                class="w-100"
                :class="{ _active: isActive(group) }"
                @click.prevent="selectGroup(group)"
              >
                {{ group.name }}
              </button>
            </li>
          </div>
        </div>

        <!-- Мікрорайон / Вулиця / Номер будинку -->
        <div class="row">
          <!-- Мікрорайон -->
          <div v-if="!isEnabledSuburb" class="col-12">
            <div class="form-group form-group-filter">
              <label class="filter-modal-label">Мікрорайон</label>

              <multiselect
                v-model="microdistrict"
                placeholder="Почніть вводити:"
                :close-on-select="false"
                :clear-on-select="true"
                :show-no-results="false"
                label="name"
                track-by="id"
                :show-labels="false"
                :multiple="true"
                :options="optionsMicroDistricts"
                :internalSearch="false"
                class="_sm"
                @search-change="findMicrodistrict"
              >
                <span slot="noResult">Немає результатів!!!</span>
              </multiselect>
            </div>
          </div>

          <!-- Вулиця -->
          <div class="col-12">
            <div class="form-group form-group-filter">
              <label class="filter-modal-label">Вулиця</label>

              <multiselect
                v-model="street"
                placeholder="Почніть вводити:"
                :close-on-select="false"
                :clear-on-select="true"
                :show-no-results="false"
                label="name"
                track-by="id"
                :show-labels="false"
                :options="optionsStreets"
                :multiple="true"
                :internalSearch="false"
                class="_sm"
                @search-change="findStreets"
              >
                <span slot="noResult">Немає результатів!!!</span>
              </multiselect>
            </div>
          </div>

          <!-- Номер будинку -->
          <div v-if="isEnabledSuburb" class="col-6">
            <div class="form-group form-group-filter">
              <label class="filter-modal-label">Номер будинку</label>

              <input v-model="house_number" class="form-input" />
            </div>
          </div>
        </div>

        <!-- Номер будинку / ЖК -->
        <div v-if="!isEnabledSuburb" class="row align-content-center">
          <!-- Номер будинку -->
          <div class="col-6">
            <div class="form-group form-group-filter">
              <label class="filter-modal-label">Номер будинку</label>

              <input v-model="house_number" class="form-input" />
            </div>
          </div>

          <!-- ЖК -->
          <div v-if="!isLand" class="col-6">
            <!-- ЖК, ТЦ, ТРЦ, БЦ (Commerce) -->
            <div v-if="isCommerce" class="form-group form-group-filter">
              <label class="filter-modal-label">ЖК, ТЦ, ТРЦ, БЦ</label>

              <multiselect
                v-model="building"
                placeholder="Почніть вводити:"
                :close-on-select="false"
                :clear-on-select="true"
                :show-no-results="false"
                label="name"
                track-by="id"
                :show-labels="false"
                :options="optionsBuildings"
                :multiple="true"
                class="_sm"
                @search-change="findBuildings"
              >
                <span slot="noResult">Немає результатів!!!</span>
              </multiselect>
            </div>

            <!-- ЖК (Other) -->
            <div v-else class="form-group form-group-filter">
              <label class="filter-modal-label">ЖК</label>

              <multiselect
                v-model="residential_complex"
                placeholder="Почніть вводити:"
                :close-on-select="false"
                :clear-on-select="true"
                :show-no-results="false"
                label="name"
                track-by="id"
                :show-labels="false"
                :options="optionsResidentialComplexes"
                :multiple="true"
                class="_sm"
                @search-change="findResidentialComplex"
              >
                <span slot="noResult">Немає результатів!!!</span>
              </multiselect>
            </div>
          </div>
        </div>

        <!-- Літера / Корпус -->
        <div class="row">
          <!-- Літера -->
          <div class="col-6">
            <div class="form-group form-group-filter">
              <label class="filter-modal-label"></label>

              <input
                type="text"
                placeholder="Літера"
                v-model="house_letter"
                class="form-input"
              />
            </div>
          </div>

          <!-- Корпус -->
          <div v-if="!isHouse && !isLand && !isParking" class="col-6">
            <div class="form-group form-group-filter">
              <label class="filter-modal-label"></label>

              <input
                type="number"
                v-model="house_hull"
                placeholder="Корпус"
                class="form-input"
              />
            </div>
          </div>
        </div>

        <!-- Непарні / Парні -->
        <div class="row">
          <div class="col-12">
            <div
              id="checkers"
              class="btn-group-toggle form-checkbox-b type-filter__even-number"
              data-toggle="buttons"
            >
              <!-- Непарні -->
              <label class="btn">
                <input
                  type="checkbox"
                  v-model="house_number_even"
                  class="form-input"
                />

                <div class="btn-check-border">
                  <icon name="check_icon" class="btn-check-icon" />
                </div>

                <span>Непарні</span>
              </label>

              <!-- Парні -->
              <label class="btn">
                <input
                  type="checkbox"
                  v-model="house_number_odd"
                  class="form-input"
                />

                <div class="btn-check-border">
                  <icon name="check_icon" class="btn-check-icon" />
                </div>

                <span>Парні</span>
              </label>
            </div>
          </div>
        </div>

        <!-- Гаражні кооперативи -->
        <div v-if="isParking" class="row">
          <div class="col-12">
            <label class="filter-modal-label">Гаражні кооперативи</label>

            <multiselect
              v-model="cooperative"
              :close-on-select="false"
              :clear-on-select="true"
              :show-no-results="false"
              :show-labels="false"
              :multiple="true"
              :searchable="true"
              label="name"
              track-by="id"
              :options="[...garageCooperatives]"
              placeholder="Почніть вводити:"
              class="_sm"
              @search-change="findGarageCooperatives"
            >
              <span slot="noResult">Немає результатів!!!</span>
            </multiselect>
          </div>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex';
import { ESTATE_TYPES } from '@/helpers/constants';
import Multiselect from 'vue-multiselect';
import FilterItemWrapper from '../FilterItemWrapper.vue';

export default {
  name: 'FilterAddress',
  components: {
    FilterItemWrapper,
    Multiselect
  },
  data() {
    return {
      timer: null,
      groups: [
        {
          name: 'Лівий берег',
          districts: [
            {
              city: 1,
              id: 6,
              name: 'Амур-Нижнеднепровский район'
            },
            {
              city: 1,
              id: 8,
              name: 'Самарский район'
            },
            {
              city: 1,
              id: 7,
              name: 'Индустриальный район'
            }
          ]
        },
        {
          name: 'Правий берег',
          districts: [
            {
              city: 1,
              id: 4,
              name: 'новокадацький'
            },
            {
              city: 1,
              id: 1,
              name: 'соборний'
            },
            {
              city: 1,
              id: 3,
              name: 'центральний'
            },
            {
              city: 1,
              id: 5,
              name: 'чечеловський'
            },
            {
              city: 1,
              id: 2,
              name: 'шевченківський'
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapState({
      buildingType: (state) => state.filter_header.buildingType
    }),
    ...mapGetters({
      optionsCities: 'cities',
      optionsDistricts: 'districts',
      optionsMicroDistricts: 'microdistricts',
      optionsStreets: 'streets',
      optionsResidentialComplexes: 'residentialComplex',
      existsTurnOnFilterAddress: 'existsTurnOnFilterAddress',
      optionsBuildings: 'buildings',
      garageCooperatives: 'garageCooperatives',
      getForm: 'getForm'
    }),
    isFlat() {
      return this.buildingType === ESTATE_TYPES.flat;
    },
    isHouse() {
      return this.buildingType === ESTATE_TYPES.house;
    },
    isCommerce() {
      return this.buildingType === ESTATE_TYPES.commerce;
    },
    isLand() {
      return this.buildingType === ESTATE_TYPES.land;
    },
    isParking() {
      return this.buildingType === ESTATE_TYPES.parking;
    },
    isEnabledSuburb({ city = [] }) {
      const [{ is_city: isCity = true } = {}] = city;
      return !isCity;
    },
    district: {
      get() {
        return this.$store.state.filter_address.form.district;
      },
      set(data) {
        this.changeDistrict(data);
      }
    },
    microdistrict: {
      get() {
        return this.$store.state.filter_address.form.microdistrict;
      },
      set(data) {
        this.changeMicrodistrict(data);
      }
    },
    city: {
      get({ $store: { state } }) {
        return state.filter_address.form.city;
      },
      set(value) {
        if (value) {
          this.changeCity([value]);
        }
      }
    },
    street: {
      get() {
        return this.$store.state.filter_address.form.street;
      },
      set(data) {
        this.changStreet(data);
      }
    },
    residential_complex: {
      get() {
        return this.$store.state.filter_address.form.residential_complex;
      },
      set(data) {
        this.changResidentialComplex(data);
      }
    },
    building: {
      get() {
        return this.$store.state.filter_address.form.building;
      },
      set(data) {
        this.changBuilding(data);
      }
    },
    house_number_even: {
      get() {
        return this.$store.state.filter_address.form.house_number_even;
      },
      set(val) {
        this.changHouseNumberEven(val);
      }
    },
    house_number_odd: {
      get() {
        return this.$store.state.filter_address.form.house_number_odd;
      },
      set(val) {
        this.changHouseNumberOdd(val);
      }
    },
    house_number: {
      get() {
        return this.$store.state.filter_address.form.house_number;
      },
      set(val) {
        this.changHouseNumber(val);
      }
    },
    house_letter: {
      get() {
        return this.$store.state.filter_address.form.house_letter;
      },
      set(val) {
        this.changNumberLetter(val);
      }
    },
    house_hull: {
      get() {
        return this.$store.state.filter_address.form.house_hull;
      },
      set(val) {
        this.changNumberHull(val);
      }
    },
    cooperative: {
      get() {
        return this.$store.state.filter_address.form.cooperative;
      },
      set(data) {
        this.changeParkingCooperative(data);
      }
    }
  },
  watch: {
    optionsCities: {
      handler(cities) {
        if (cities.length > 1 && !this.city.length) {
          this.city = [cities[0]];
        }
      },
      immediate: true
    },
    city: {
      handler(city) {
        if (city && city.length > 0) {
          this.getDistrictsByCities(city[0].id);
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions([
      'handleGetCities',
      'handleGetDistrictByCities',
      'handleGetDistrict',
      'handleGetMicroDistrict',
      'handleGetStreet',
      'handleGetResidentialComplex',
      'handleGetBuildings',
      'handleGetGarageCooperatives'
    ]),
    ...mapMutations({
      changeDistrict: 'CHANGE_DISTRICT',
      changeMicrodistrict: 'CHANGE_MICRODISTRICT',
      changStreet: 'CHANGE_STREET',
      changResidentialComplex: 'CHANGE_RESIDENTIAL_COMPLEX',
      changBuilding: 'CHANGE_BUILDING',
      changHouseNumberEven: 'CHANGE_HOUSE_NUMBER_EVEN',
      changHouseNumberOdd: 'CHANGE_HOUSE_NUMBER_ODD',
      changHouseNumber: 'CHANGE_HOUSE_NUMBER',
      changNumberLetter: 'CHANGE_HOUSE_LETTER',
      changNumberHull: 'CHANGE_HOUSE_HULL',
      changeParkingCooperative: 'CHANGE_PARKING_COOPERATIVE',
      resetFilterAddress: 'RESET_FILTER_ADDRESS',
      changeCity: 'CHANGE_CITY'
    }),
    async getDistrictsByCities(id) {
      this.handleGetDistrictByCities(id);
    },
    findMicrodistrict(name) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.handleGetMicroDistrict({ name });
      }, 300);
    },
    findStreets(name) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.handleGetStreet({ name });
      }, 300);
    },
    findResidentialComplex(name) {
      if (name.length > 0 && name.length < 3) return;

      this.handleGetResidentialComplex({ name });
    },
    findBuildings(name) {
      if (name.length > 0 && name.length < 3) return;

      this.handleGetBuildings({ name });
    },
    findGarageCooperatives(name) {
      if (name.length > 0 && name.length < 3) return;

      this.handleGetGarageCooperatives({ name });
    },
    selectGroup(group) {
      this.district = this.optionsDistricts.filter((district) =>
        group.districts.some(
          (groupDistrict) => groupDistrict.id === district.id
        )
      );
    },
    isActive(group) {
      return group.districts.every((district) =>
        this.district.some(
          (selectedDistrict) => selectedDistrict.id === district.id
        )
      );
    }
  }
};
</script>

<style scoped>
#checkers {
  flex-direction: row;
}
</style>
